import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let CDropDownValue = class CDropDownValue extends Vue {
    get deptName() {
        return this.depart[0]?.name;
    }
    get isShowOverlay() {
        return this.value?.includes('/');
    }
};
__decorate([
    Prop({ default: '' })
], CDropDownValue.prototype, "value", void 0);
__decorate([
    Prop()
], CDropDownValue.prototype, "depart", void 0);
CDropDownValue = __decorate([
    Component({
        name: 'CDropDownValue',
    })
], CDropDownValue);
export default CDropDownValue;
